<template>
  <div class='home'>
    <!-- header -->
    <Header></Header>

    <div class='container'>
      <!-- 左侧导航栏 -->
      <side-bar></side-bar>

      <div class='home-content'>
        <!-- 首屏视频 -->
        <div id='videoBox' class='video-box'>
          <div class='intro-content'>
            <h2>欢迎来到DV101</h2>
            <div class='intro-title'>
              <h3>居住卡、建造、AVATAR、宝箱、彩蛋、数字商城...更多精彩玩法就在DV101星球</h3>
            </div>
            <h4>感受真实场景，体验VR动画，聆听燃爆全场的演唱会，盘丝洞偶遇白骨精，昆仑山玉虚宫里坐而论道，比武，打怪，娶亲，会议，人人都是元宇宙的创造者</h4>
            <!-- <div class='video-button'>
              <a href="https://discord.gg/Xudxrra5Ce" class="btn-get-started scrollto" target="_blank" rel="noreferrer">加入社区</a>
            </div> -->
          </div>
          <!-- <div class='top-bg'></div> -->
          <div class='video-bg' id='video-bg'>
            <video
              class='intro-video'
              :src='videoUrl'
              autoplay='autoplay'
              loop='loop'
              preload='auto'
              muted='muted'>
            </video>
          </div>
        </div>

        <!-- dreamverse简介 -->
        <div class='section introduce animate' ref='introduce'>
          <div class='wrapper content-box'>
            <div class='intro-left'>
              <h2>了解 DV101星球</h2>
              <p>玩家可以在购买居住卡后在自己的领地上自由创造完美的世界，可进入其他玩家的领地观看和互动。<br />
              在获取宝箱等创造收益的同时，打造属于自己的凭条。<br />
              玩家可以通过虚拟现实设备在世界上进行享受！</p>
            </div>
            <img class='intro-right' :src='introSrc' />
          </div>
        </div>

        <!-- dreamverse土地 -->
        <div class='section land' ref='land'>
          <div class='wrapper land-box'>
            <img class='land-left' :src='landSrc' />
            <div class='land-right'>
              <div class='land-intro'>
                <h2>逼真还原的场景</h2>
                <p>“DV101总部－漫城（未来世界），根据《西游记》IP打造的“花果山“和 根据《山海经》《封神榜》打造的”元世界“。 用户可以在自己喜欢的城市购买土地和资产，也可以通过身份卡获得指定城市的购买资格。</p>
              </div>
              <div class='land-button'>
                <router-link to='/map' class='search-map'>搜索地图</router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- dreamverse场景 -->
        <div class='section scene' ref='scene'>
          <div class='wrapper scene-box'>
            <div class='scene-left'>
              <h2>丰富的Avatar与VR场景</h2>
              <p>包含了多种角色：机甲悟空，白骨精，公民，山海经神兽等。同时丰富的VR场景可以使玩家身临其境的感受、体验元宇宙的魅力。</p>
            </div>
            <img class='scene-right' :src='sceneSrc' />
          </div>
        </div>

        <!-- dreamverse玩法 -->
        <div class='section play' ref='play'>
          <div class='wrapper play-box'>
            <img class='play-left' :src='playSrc' />
            <div class='play-right'>
              <div class='play-intro'>
                <h2>最具中国文化风格的IP世界</h2>
                <p>DV101将以IP为牵引，依托中国传统神话为背景，还原再现《山海经》、《封神榜》、《西游记》等经典IP，根据不同的场景、人物、角色将通过技术真实再现还原再创造，打造出最具中国文化元素的元宇宙</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 底部 -->
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import SideBar from '@/components/SideBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  data: function() {
    return {
      videoUrl: require('../assets/video/dv101.mp4'),
      introSrc: require('../assets/images/circle.png'),
      landSrc: require('../assets/images/land.png'),
      sceneSrc: require('../assets/images/scene.png'),
      playSrc: require('../assets/images/ip.png'),
      i: 0
    }
  },
  components: {
    Header,
    Footer,
    SideBar
  },
  mounted() {
    const that = this
    const homeContent = document.querySelector('.home-content')
    homeContent.addEventListener('scroll', that.handleScroll, true)
  },
  methods: {
    handleScroll(e) {
      var that = this
      const scrollTop = e.target.scrollTop
      const scroll = scrollTop - that.i
      that.i = scrollTop
      if (scroll > 0) {
        if (scrollTop > 800 && scrollTop < 950) {
          that.$refs.land.classList.add('animate')
        } else if (scrollTop > 1500 && scrollTop < 1700) {
          that.$refs.land.classList.add('animate')
          that.$refs.scene.classList.add('animate')
        } else if (scrollTop > 2100 && scrollTop < 2200) {
          that.$refs.land.classList.add('animate')
          that.$refs.scene.classList.add('animate')
          that.$refs.play.classList.add('animate')
        } else if (scrollTop > 2600) {
          that.$refs.land.classList.add('animate')
          that.$refs.scene.classList.add('animate')
          that.$refs.play.classList.add('animate')
        }
      } else {
        if (scrollTop > 1600 && scrollTop < 1700) {
          that.$refs.play.classList.remove('animate')
        } else if (scrollTop > 800 && scrollTop < 900) {
          that.$refs.play.classList.remove('animate')
          that.$refs.scene.classList.remove('animate')
        } else if (scrollTop < 400) {
          that.$refs.play.classList.remove('animate')
          that.$refs.scene.classList.remove('animate')
          that.$refs.land.classList.remove('animate')
        }
      }
    }
  }
}
</script>
